import humanizeDuration from 'humanize-duration'
import parse from 'parse-duration'
import i18n from "../plugins/i18n"
import momentTimezone from "moment-timezone"
function humanReadableTimestamp(timestamp) {
  return new Date(timestamp).toLocaleString(i18n.global.locale.value,
    {
      second: "2-digit",
      minute: "2-digit",
      hour: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric"}
  );
}

function humanReadableDuration(start, end=undefined, units=undefined, round=false) {
  let duration = 0
  if(end === undefined) duration = start; else duration = end - start;
  if(!units) units = ['y', 'mo', 'd', 'h', 'm', 's']
  return humanizeDuration(duration, {units, round, language: i18n.global.locale.value} )
}

function humanReadableDurationInDays(start, end=undefined) {
  let duration = 0
  if(end === undefined) duration = start; else duration = end - start;
  return humanizeDuration(duration, {units: ['d'], language: i18n.global.locale.value, round: true} )
}

function humanReadableDurationInHoursAndMinutes(start, end=undefined) {
  let duration = 0
  if(end === undefined) duration = start; else duration = end - start;
  return humanizeDuration(duration, {units: ['h', 'm'], language: i18n.global.locale.value, round: true} )
}

function parseDuration(duration) {
  return parse(duration)
}

function getAvailableTimezones() {
  return momentTimezone.tz.names()
}

function getCurrentTimezone() {
  return momentTimezone.tz.guess()
}
export {humanReadableDurationInHoursAndMinutes, humanReadableTimestamp, humanReadableDuration, parseDuration, getAvailableTimezones, getCurrentTimezone, humanReadableDurationInDays}
