import i18n from "../../plugins/i18n"
import {useInstanceStore} from "@/store/backendInstances";
import {EQ, GT, GTE, IN, LT, LTE, NEQ, NIN} from "@/composables/filters/operators";
import {
  getAvailableProperties,
  getDataType,
  getOperators,
  getOptions, getOptionTitle,
  getPropertyTitle, getSortableProperties
} from "@/composables/filters/filterHelpers";

const {t} = i18n.global


const properties = {
  status: {
    title: t("filters.artifacts.properties.status.title"),
    operators: [EQ, IN, NIN, NEQ],
    dataType: "autocomplete",
    options: [
      {
        id: "created",
        title: t("filters.data_collector.records.properties.status.options.created.title"),
        enabled: () => true,
      },
      {
        id: "finished",
        title: t("filters.data_collector.records.properties.status.options.finished.title"),
        enabled: () => true,
      },
      {
        id: "archived",
        title: t("filters.data_collector.records.properties.status.options.archived.title"),
        enabled: () => true,
      },
    ]
  },
  recordName: {
    title: t("filters.data_collector.records.properties.recordName.title"),
    operators: [EQ, NEQ, LT, GT],
    dataType: "String",
  },
  creationTimestamp: {
    title: t("filters.data_collector.records.properties.creationTimestamp.title"),
    operators: [EQ, NEQ, LTE, GTE],
    dataType: "timestamp",
  }
}

const sortableProperties = [
  "creationTimestamp",
  "randomizedIdentifier",
  "recordName",
  "status",
  "requiredAnnotationCount",
  "annotationType",
  "finishedAnnotationCount",
]

export default {
  getOptionTitle: (propertyId, optionId) => getOptionTitle(properties, propertyId, optionId),
  getPropertyTitle: (propertyId) => getPropertyTitle(properties, propertyId),
  getAvailableProperties: () => getAvailableProperties(properties),
  getOperators: (propertyId) => getOperators(properties, propertyId),
  getDataType: (propertyId) => getDataType(properties, propertyId),
  getOptions: (propertyId) => getOptions(properties, propertyId),
  getSortableProperties: () => getSortableProperties(sortableProperties, properties)
}
