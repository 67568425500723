import i18n from "../../plugins/i18n"
import {useInstanceStore} from "@/store/backendInstances";
import {EQ, GT, GTE, IN, LT, LTE, NEQ, NIN} from "@/composables/filters/operators";
import {
  getAvailableProperties,
  getDataType,
  getOperators,
  getOptions, getOptionTitle,
  getPropertyTitle, getSortableProperties
} from "@/composables/filters/filterHelpers";

const {t} = i18n.global


const properties = {
  status: {
    title: t("filters.artifacts.properties.status.title"),
    operators: [EQ, IN, NIN, NEQ],
    dataType: "autocomplete",
    options: [
      {
        id: "created",
        title: t("filters.data_collector.annotations.properties.status.options.created.title"),
        enabled: () => true,
      },
      {
        id: "finished",
        title: t("filters.data_collector.annotations.properties.status.options.finished.title"),
        enabled: () => true,
      },
      {
        id: "stale",
        title: t("filters.data_collector.annotations.properties.status.options.stale.title"),
        enabled: () => true,
      },
    ]
  },
  annotationType: {
    title: t("filters.data_collector.annotations.properties.annotationType.title"),
    operators: [EQ, NEQ, IN, NIN],
    dataType: "String",
  },
  creationTimestamp: {
    title: t("filters.data_collector.annotations.properties.creationTimestamp.title"),
    operators: [EQ, NEQ, LTE, GTE],
    dataType: "timestamp",
  },
  lastUpdateTimestamp: {
    title: t("filters.data_collector.annotations.properties.lastUpdateTimestamp.title"),
    operators: [EQ, NEQ, LTE, GTE],
    dataType: "timestamp",
  },
  lastPayloadUpdateTimestamp: {
    title: t("filters.data_collector.annotations.properties.lastPayloadUpdateTimestamp.title"),
    operators: [EQ, NEQ, LTE, GTE],
    dataType: "timestamp",
  },
  dataCollectionRecordId: {
    title: t("filters.data_collector.annotations.properties.dataCollectionRecordId.title"),
    operators: [EQ, NEQ, IN, NIN],
    dataType: "String",
  },
}

const sortableProperties = [
  "creationTimestamp",
  "lastUpdateTimestamp",
  "lastPayloadUpdateTimestamp",
  "dataCollectionRecordId",
  "status",
  "annotationType",
]

export default {
  getOptionTitle: (propertyId, optionId) => getOptionTitle(properties, propertyId, optionId),
  getPropertyTitle: (propertyId) => getPropertyTitle(properties, propertyId),
  getAvailableProperties: () => getAvailableProperties(properties),
  getOperators: (propertyId) => getOperators(properties, propertyId),
  getDataType: (propertyId) => getDataType(properties, propertyId),
  getOptions: (propertyId) => getOptions(properties, propertyId),
  getSortableProperties: () => getSortableProperties(sortableProperties, properties)
}
