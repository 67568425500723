import {defineStore} from 'pinia'
import {useInstanceStore} from "@/store/backendInstances";
import backendHandler from "@/BackendAPI";
import IncidentsAssignedToMeSyncable from "@/syncing/syncables/IncidentsAssignedToMeSyncable.js";
import SyncableQueue from "@/syncing/SyncableQueue.js";
import DeadAnimalCountsSyncable from "@/syncing/syncables/DeadAnimalCountsSyncable.js";

export const useIncidentStore = defineStore('incidents', {
  state: () => ({
    incidentsAssignedToMe: {},
    deadAnimalCounts: {},
  }),
  persist: false,
  share: {
    enable: true,
    initialize: true,
  },
  getters: {
    getIncidentsAssignedToMe(state) {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => state.incidentsAssignedToMe[globalUniqueIdentifier]
    },
    getDeadAnimalCounts(state) {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => state.deadAnimalCounts[globalUniqueIdentifier]
    },
  },
  actions: {
    /**
     * Loads all incidents of a specific type that are 'open' at a specific timestamp.
     * 'Open' means that the firstEventTimestamp of the incident is before the timestamp and the lastEventTimestamp is after the timestamp,
     * or that the firstEventTimestamp of the incident is before the timestamp and the incident is not closed yet.
     * @param {String} incidentType - The type of incident to load
     * @param {Number} timestamp - Unix Timestamp (ms) at which the incident should be open
     * @param globalUniqueIdentifier
     * @returns {Promise<Array>} - Array of Incidents
     */
    async loadIncidentsOpenAtSpecificTimestamp(incidentType, timestamp, additionalFilters = [], globalUniqueIdentifier = undefined, signal = undefined) {
      const query = [
        [
          {key: "type", operator: "==", value: incidentType},
          {key: "firstEventTimestamp", operator: "<=", value: timestamp},
          {key: "lastEventTimestamp", operator: ">=", value: timestamp}
        ],
        [
          {key: "type", operator: "==", value: incidentType},
          {key: "firstEventTimestamp", operator: "<=", value: timestamp},
          {key: "state", operator: "!=", value: "closed"}
        ]
      ]
      for (let i = 0; i < query.length; i++) {
        additionalFilters.forEach(filter => query[i].push(filter))
      }
      const incidentResponse = await backendHandler.post(globalUniqueIdentifier, {
        path: "/api/incidents/query",
        data: {query},
        signal
      })
      if (!incidentResponse.ok) return null;
      return incidentResponse.data
    },

    /**
     * Load All Incidents of a specific incident type with a firstEventTimestamp before a specific timestamp
     * @param {String} incidentType - The type of incident to load
     * @param {Number} timestamp - Unix Timestamp (ms) the incidents should be created before
     * @returns {Promise<Array>} - Array of Incidents
     */
    async loadIncidentsCreatedBeforeSpecificTimestamp(incidentType, timestamp) {
      const query = [
        {key: "type", operator: "==", value: incidentType},
        {key: "firstEventTimestamp", operator: "<=", value: timestamp},
      ]
      const incidentResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/incidents/query",
        data: {query}
      })
      return incidentResponse.data
    },

    /**
     * Load Incidents of specified Incident Types that have their firstEventTimestamp in the specified timerange
     * @param {Array<String>} incidentTypes
     * @param {Number} startTimestamp
     * @param {Number} endTimestamp
     * @param {Object} projection
     * @param {Object} order
     * @param {String} globalUniqueIdentifier
     * @returns {Promise<Array>}
     */
    async loadIncidentsCreatedBetweenSpecificTimestamps(incidentTypes, startTimestamp, endTimestamp, projection, order, additionalFilters = [], globalUniqueIdentifier = undefined) {
      const query = [
        [
          {key: "firstEventTimestamp", operator: ">=", value: startTimestamp},
          {key: "firstEventTimestamp", operator: "<=", value: endTimestamp},
        ],
      ]

      if (incidentTypes.length) {
        for (let i = 0; i < query.length; i++) {
          query[i].push({key: "type", operator: "in", value: incidentTypes})
        }
      }
      for (let i = 0; i < query.length; i++) {
        additionalFilters.forEach(filter => query[i].push(filter))
      }
      return await this.queryIncidents({query: query, projection, order}, globalUniqueIdentifier)
    },

    /**
     * Load Incidents of specified Incident Types that have their firstEventTimestamp in the specified timerange and are either not closed yet or have their lastEventTimestamp in the specified timerange
     * @param {Array<String>} incidentTypes
     * @param {Number} startTimestamp
     * @param {Number} endTimestamp
     * @param {Object} projection
     * @param {Object} order
     * @param {String} globalUniqueIdentifier
     * @returns {Promise<Array>}
     */
    async loadIncidentsOpenBetweenSpecificTimestamps(incidentTypes, startTimestamp, endTimestamp, projection, order, additionalFilters = [], globalUniqueIdentifier = undefined) {
      const query = [
        [
          {key: "firstEventTimestamp", operator: "<=", value: endTimestamp},
          {key: "lastEventTimestamp", operator: ">=", value: startTimestamp}
        ],
        [
          {key: "firstEventTimestamp", operator: "<=", value: endTimestamp},
          {key: "state", operator: "!=", value: "closed"}
        ]
      ]

      if (incidentTypes.length) {
        for (let i = 0; i < query.length; i++) {
          query[i].push({key: "type", operator: "in", value: incidentTypes})
        }
      }
      for (let i = 0; i < query.length; i++) {
        additionalFilters.forEach(filter => query[i].push(filter))
      }
      return await this.queryIncidents({query: query, projection, order}, globalUniqueIdentifier)
    },

    /**
     * Generic Incident Query
     * @param payload
     * @param globalUniqueIdentifier
     * @returns {Promise<*|null>}
     */
    async queryIncidents(payload, globalUniqueIdentifier = undefined) {
      if (!globalUniqueIdentifier) globalUniqueIdentifier = useInstanceStore().selectedInstanceId
      const incidentResponse = await backendHandler.post(globalUniqueIdentifier, {
        path: "/api/incidents/query",
        data: payload
      })
      if (!incidentResponse.ok) return null;
      return incidentResponse.data
    },

    async getBarnStateIncident(globalUniqueIdentifier = undefined) {
      const incidentResponse = await backendHandler.post(globalUniqueIdentifier, {
        path: "/api/incidents/queryOne",
        data: {query: [{key: "type", operator: "==", value: "barnState"}]}
      })
      if (!incidentResponse.ok) return null;
      return incidentResponse.data
    },

    /**
     * Load Specific Incident
     * @param incidentId
     * @returns {Promise<*|null>}
     */
    async getIncident(incidentId) {
      const incidentResponse = await backendHandler.get(useInstanceStore().selectedInstanceId, {path: "/api/incidents/" + incidentId})
      if (!incidentResponse.ok) return null;
      return incidentResponse.data
    },

    /**
     * Load All Events for a specific Incident
     * @param incidentId
     * @returns {Promise<*|null>}
     */
    async loadEventsForIncident(incidentId) {
      const query = [{key: "incidentId", operator: "==", value: incidentId},]
      const incidentEventResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/incidentEvents/query",
        data: {query, order: {"eventTimestamp": "asc"}}
      })
      if (!incidentEventResponse.ok) return null;
      return incidentEventResponse.data
    },

    async loadFirstEventForIncident(incidentId) {
      const query = [{key: "incidentId", operator: "==", value: incidentId}]
      const incidentEventResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/incidentEvents/queryOne",
        data: {query, order: {"eventTimestamp": "asc"}}
      })
      if (!incidentEventResponse.ok) return null;
      return incidentEventResponse.data
    },
    async loadCommentsForIncident(incidentId) {
      const query = [
        {key: "incidentId", operator: "==", value: incidentId},
        {key: "incidentEventId", operator: "exists", value: false}
      ]
      const commentResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/incidentComments/query",
        data: {query, order: {"creationTimestamp": "asc"}}
      })
      if (!commentResponse.ok) return null;
      return commentResponse.data
    },

    /**
     * Load Event Comments for a specific Incident Event
     * @param incidentId
     * @param incidentEventId
     * @returns {Promise<*|null>}
     */
    async loadEventCommentsForIncidentEvent(incidentId, incidentEventId) {
      const query = [{key: "incidentId", operator: "==", value: incidentId}, {
        key: "incidentEventId",
        operator: "==",
        value: incidentEventId
      }]
      const commentResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/incidentComments/query",
        data: {query, order: [{"incidentEventId": "asc"}, {"creationTimestamp": "asc"}]}
      })
      if (!commentResponse.ok) return null;
      return commentResponse.data
    },

    /**
     * Create a new Comment for either an incident or an incident event
     * @param text
     * @param incidentId
     * @param incidentEventId
     * @param parentCommentId
     * @returns {Promise<void>}
     */
    async createNewIncidentComment(text, incidentId, incidentEventId = undefined, parentCommentId = undefined) {
      const payload = {incidentComment: {text}}
      if (incidentEventId) payload.incidentComment.incidentEventId = incidentEventId
      if (parentCommentId) payload.incidentComment.parentCommentId = parentCommentId
      await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/incidentComments/" + incidentId,
        data: payload
      })
    },

    /**
     * Update the text of a comment
     * @param incidentCommentId
     * @param text
     * @returns {Promise<void>}
     */
    async updateCommentText(incidentCommentId, text) {
      const payload = {commentText: text}
      await backendHandler.patch(useInstanceStore().selectedInstanceId, {
        path: "/api/incidentComments/" + incidentCommentId,
        data: payload
      })
    },

    /**
     * Delete a comment
     * @param incidentCommentId
     * @returns {Promise<void>}
     */
    async deleteComment(incidentCommentId) {
      await backendHandler.delete(useInstanceStore().selectedInstanceId, {path: "/api/incidentComments/" + incidentCommentId})
    },

    /**
     * Restore a deleted comment
     * @param incidentCommentId
     * @returns {Promise<void>}
     */
    async restoreComment(incidentCommentId) {
      await backendHandler.put(useInstanceStore().selectedInstanceId, {path: "/api/incidentComments/" + incidentCommentId})
    },

    /**
     * Add or remove a reaction for a comment
     * @param incidentCommentId
     * @param unicodeString
     * @returns {Promise<void>}
     */
    async addOrRemoveReactionForComment(incidentCommentId, unicodeString) {
      await backendHandler.put(useInstanceStore().selectedInstanceId, {path: "/api/incidentComments/" + incidentCommentId + "/" + unicodeString})
    },

    /**
     * Get the schema for a specific incident type. This returns the simplified schema.
     * @param incidentType
     * @returns {Promise<*|null>}
     */
    async getSimplifiedIncidentSchema(incidentType) {
      const schemaResponse = await backendHandler.get(useInstanceStore().selectedInstanceId, {path: "/api/incidents/handlers/" + incidentType + "/true"})
      if (schemaResponse.ok) return schemaResponse.data;
      return null;
    },

    /**
     * Generic Trigger for an incident
     * @param incidentData
     * @returns {Promise<*|null>}
     */
    async triggerGenericIncident(incidentData) {
      const incidentResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/incidents",
        data: {incidentData: incidentData}
      })
      if (!incidentResponse.ok) return null;
      return incidentResponse.data
    },

    async triggerDeadAnimal(payload) {
      const response = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/deadAnimals/detection",
        data: payload
      })
      if (!response.ok) return null;
      return response.data
    },

    async markDeadAnimalAsRemoved(incidentId, removedTimestamp) {
      const response = backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/deadAnimals/markRemoved/" + incidentId,
        data: {timestamp: removedTimestamp}
      })
      if (!response.ok) return null;
      return response.data
    },

    async markDeadAnimalAsNotFalsePositive(incidentId) {
      const response = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/deadAnimals/revertFalsePositive/" + incidentId
      })
      if (!response.ok) return null;
      return response.data
    },

    async markDeadAnimalAsFalsePositive(incidentId, falsePositiveTimestamp) {
      const response = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: "/api/deadAnimals/falsePositive/" + incidentId,
        data: {timestamp: falsePositiveTimestamp}
      })
      if (!response.ok) return null;
      return response.data
    },

    async closeIncident(incidentId, forceClose = false) {
      const response = await backendHandler.put(useInstanceStore().selectedInstanceId, {
        path: "/api/incidents/" + incidentId,
        data: {forceClose}
      })
      if (!response.ok) return null;
      return response.data
    },

    async reopenIncident(incidentId) {
      const response = await backendHandler.put(useInstanceStore().selectedInstanceId, {path: "/api/incidents/reopen/" + incidentId})
      if (!response.ok) return null;
      return response.data
    },

    async deleteIncident(incidentId) {
      const response = await backendHandler.delete(useInstanceStore().selectedInstanceId, {path: "/api/incidents/" + incidentId})
      if (!response.ok) return null;
      return response.data
    },

    async assignIncidentToAccount(incidentId, accountId) {
      const response = await backendHandler.put(useInstanceStore().selectedInstanceId, {path: "/api/incidents/assign/" + incidentId + "/" + accountId})
      return response.ok
    },

    async unAssignIncident(incidentId) {
      const response = await backendHandler.put(useInstanceStore().selectedInstanceId, {path: "/api/incidents/unassign/" + incidentId})
      return response.ok
    },

    keepIncidentsAssignedToMeLoadedForInstance(globalUniqueIdentifier) {
      if (!SyncableQueue.doesSyncableExist(globalUniqueIdentifier, IncidentsAssignedToMeSyncable.getSyncableHandle())) {
        const syncable = new IncidentsAssignedToMeSyncable(globalUniqueIdentifier)
        SyncableQueue.addSyncable(syncable)
      }
    },

    keepDeadAnimalCountsLoadedForInstance(globalUniqueIdentifier) {
      if (!SyncableQueue.doesSyncableExist(globalUniqueIdentifier, DeadAnimalCountsSyncable.getSyncableHandle())) {
        const syncable = new DeadAnimalCountsSyncable(globalUniqueIdentifier)
        SyncableQueue.addSyncable(syncable)
      }
    }
  }
})
