import i18n from "../../plugins/i18n"
import {EQ, GT, GTE, IN, LT, LTE, NEQ, NIN} from "@/composables/filters/operators";
import {
  getAvailableProperties,
  getDataType,
  getOperators,
  getOptions, getOptionTitle,
  getPropertyTitle, getSortableProperties
} from "@/composables/filters/filterHelpers";
const {t} = i18n.global



const properties = {

  cameraId: {
    title: t("filters.recordings.properties.cameraId.title"),
    operators: [EQ, IN, NIN, NEQ],
    dataType: "camera",
  },
  timestamp: {
    title: t("filters.recordings.properties.timestamp.title"),
    operators: [GTE, LTE, EQ, GT, LT],
    dataType: "timestamp"
  },
  status:{
    title: t("filters.recordings.properties.status.title"),
    operators: [EQ, IN, NIN, NEQ],
    dataType: "autocomplete",
    options: [
      {
        id: "saved",
        title: t("filters.recordings.properties.status.options.saved.title"),
        enabled: () => true,
      },
      {
        id: "created",
        title: t("filters.recordings.properties.status.options.created.title"),
        enabled: () => true,
      },
      {
        id: "deleted",
        title: t("filters.recordings.properties.status.options.deleted.title"),
        enabled: () => true,
      },
      {
        id: "broken",
        title: t("filters.recordings.properties.status.options.broken.title"),
        enabled: () => true,
      }
    ]
  },
  filesizeMb: {
    title: t("filters.recordings.properties.filesizeMb.title"),
    operators: [GTE, LTE, EQ, GT, LT],
    dataType: "number"
  },
  durationSeconds: {
    title: t("filters.recordings.properties.durationSeconds.title"),
    operators: [GTE, LTE, EQ, GT, LT],
    dataType: "number"
  }
}

const sortableProperties = [
  "timestamp",
  "filesizeMb",
  "cameraId",
  "durationSeconds",
]

export default {
  getOptionTitle: (propertyId, optionId) => getOptionTitle(properties, propertyId, optionId),
  getPropertyTitle: (propertyId) => getPropertyTitle(properties, propertyId),
  getAvailableProperties: () => getAvailableProperties(properties),
  getOperators: (propertyId) => getOperators(properties, propertyId),
  getDataType: (propertyId) => getDataType(properties, propertyId),
  getOptions: (propertyId) => getOptions(properties, propertyId),
  getSortableProperties: () => getSortableProperties(sortableProperties, properties)
}
