<script setup>

import {computed, onMounted, ref} from "vue";
import {humanReadableDurationInDays, humanReadableTimestamp} from "@/composables/datetime.js";
import {useIncidentStore} from "@/store/incident.js";
const incidentStore = useIncidentStore()
const props = defineProps({
  globalUniqueIdentifier: {type: String, required: true},
  compact: {type: Boolean, default: false},
})
const loading = ref(false)
const loaded = ref(false)
const incident = ref({})
const color = computed(() => {
  if (incident.value?.state === 'barnFull') return "green"
  if (incident.value?.state === 'barnEmpty') return 'blue'
  return 'red'
})
onMounted(async () => {
  loading.value = true;
  loaded.value = false
  incident.value = await incidentStore.getBarnStateIncident(props.globalUniqueIdentifier)
  loading.value = false
  loaded.value = !!incident.value
})
</script>

<template>
  <v-layout-card
    v-if="!compact && loaded"
    :loading="loading"
  >
    <v-row
      class="float-end ma-0"
      :dense="true"
      :no-gutters="true"
    >
      <v-col cols="auto">
        <slot name="commentButton" />
      </v-col>
      <v-col cols="auto">
        <slot name="assignButton" />
      </v-col>
    </v-row>
    <v-card-title>{{ $t("incidents.barnState.title") }}</v-card-title>
    <v-card-text>
      <v-alert
        :color="color"
        :type="incident.state === 'barnFull' || incident.state === 'barnEmpty' ? 'info' : 'error'"
      >
        {{
          $t('incidents.barnState.statusTexts.' + incident.state, {
            timestamp: humanReadableTimestamp(incident.lastEventTimestamp),
            day: humanReadableDurationInDays(incident.lastEventTimestamp, Date.now())
          })
        }}
      </v-alert>
    </v-card-text>
    <v-card-actions class="justify-end">
      <slot
        name="genericActions"
        v-bind="{enableNewEventButton: true, newEventButtonText: $t('incidents.barnState.actions.new-event')}"
      />
    </v-card-actions>
  </v-layout-card>
  <v-chip
    v-if="compact && loaded"
    :color="color"
    @click="$router.push({name: 'IncidentView', params: {globalUniqueIdentifier: globalUniqueIdentifier, incidentId: incident.incidentId}})"
  >
    {{
      $t('incidents.barnState.statusTexts_short.' + incident.state, {
        timestamp: humanReadableTimestamp(incident.lastEventTimestamp),
        day: humanReadableDurationInDays(incident.lastEventTimestamp, Date.now())
      })
    }}
  </v-chip>
</template>

<style scoped>

</style>
